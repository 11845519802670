import {AdminCreateUserCommand, AdminInitiateAuthCommand, GlobalSignOutCommand, CognitoIdentityProviderClient, RespondToAuthChallengeCommand, GlobalSignOutCommandOutput, AdminCreateUserCommandOutput, AdminInitiateAuthCommandOutput, RespondToAuthChallengeCommandOutput} from "@aws-sdk/client-cognito-identity-provider";
import User from "../types/User";
import { NEW_PASSWORD_REQUIRED, ADMIN_NO_SRP_AUTH, AWS_REGION } from "../utilities/constants";
import { AxiosResponse } from "axios";
import { API } from "../utilities/util";
 


// Login
const login = async (user: User): Promise<AxiosResponse> => {
    try {
        const response = await API().post(`/login`, user)        
        return response;
    } catch (error: any) {  
        console.log("Error while sending question", error.message);              
        throw error;
    }
}


const setNewPassword = async (user: User): Promise<AxiosResponse> => {
    try {
        const response = await API().post(`/set-newpassword`, user)        
        return response;
    } catch (error: any) {  
        console.log("Error while sending question", error.message);              
        throw error;
    }
}


const logout = async (access_token: string): Promise<AxiosResponse> => {
    try {
        const response = await API().post(`/logout`, access_token)        
        return response
    } catch (error: any) {  
        console.log("Error while sending question", error.message);              
        throw error;
    }
}





const userPoolCognitoService = {
    login,
    setNewPassword,
    logout,
}

export default userPoolCognitoService;