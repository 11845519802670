import React from 'react';
import './App.css';
import { Navigate, Routes , Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Suspense, lazy } from 'react';
import Loader from './pages/Layout/Loader';
import AuthProviders from './routes/AuthProviders';
import Profile from './pages/Dashboard/Profile';

import {  RootState } from './store/authSlice';
import { GuideLines } from './pages/Guidelines/Guidelines';

const Login = lazy(() => import('./pages/Authentication/Login/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
// const Register = lazy(() => import('./pages/Authentication/Registration/Register'));
const SetNewpassword = lazy(() => import('./pages/Authentication/SetNewPassword/SetNewpassword'));

const App: React.FC = () => {
  const { isLoggedIn, email } = useSelector((state: RootState) => state.auth);
  return (
    <>
      {(isLoggedIn && email) ? <PrivateRoute /> : <PublicRoute />}
    </>
  );
}
const PrivateRoute: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <AuthProviders>
              <Dashboard />
            </AuthProviders>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthProviders>
              <Profile />
            </AuthProviders>
          }
        />
        <Route
          path="/guidelines"
          element={
            <AuthProviders>
              <GuideLines />
            </AuthProviders>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path='*' element={<Navigate to="/dashboard"></Navigate>}></Route>
      </Routes>
    </Suspense>
  );
}
const PublicRoute: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/newpassword" element={<SetNewpassword />} />
        <Route path='*' element={<Navigate to="/"></Navigate>}></Route>
      </Routes>
    </Suspense>
  );
}
export default App;


