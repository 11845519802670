
import { Header,Footer, Sidebar } from '../Layout';
import { useNavigate } from 'react-router-dom';
import { Card, Space, Image } from 'antd';
import {  useSelector } from 'react-redux';
import userImage from '../../assets/images/user.png';
import React from 'react';
import { RootState } from '../../store/authSlice';
import './profile.css';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { email } = useSelector((state:RootState) => state.auth)
  return (
    <div className='main-profile'>
      <Header />
      <div className='inside-profile'>
        <Sidebar navigate={navigate} />
        <Space direction='vertical' size="middle" className='space-card'>
          <Card title="My Profile" className='card-profile'>
            <Image
              width={200}
              src={userImage}
            />
            <div>Welcome, {email}</div>
          </Card>
        </Space>
      </div>
      <Footer />
    </div>
  )
}

export default Profile
