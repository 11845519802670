import { useNavigate } from 'react-router-dom';
import { Footer, Header, Sidebar } from '../Layout';
import './guide.css';

export const GuideLines = () => {
    const navigate = useNavigate();
    return (       
        <div className='main-profile'>
            <Header />
            <div className='inside-profile'>
                <Sidebar navigate={navigate} />
                <div className='guidelines-content'>
                    <h1>How to Use the App?</h1>
                    <section>
                        <p>Welcome to our <strong>MMU Chat Bot</strong> application! To get started, please create an account or log in if you already have one.</p>
                    </section>
                    <section>
                            <h2>How to Use Voice-to-Text</h2>
                            <p>Follow these steps to use the voice-to-text feature:</p>
                            <ol>
                                <li><strong>Activate the Microphone:</strong> Tap the microphone icon in the chat interface to begin voice input.</li>
                                <li><strong>Green Indicator:</strong> When the microphone turns green, you can start speaking your question.</li>
                                <li><strong>Deactivate Voice Input:</strong> Tap the microphone icon again to stop recording your voice.</li>
                                <li><strong>Submit Your Question:</strong> Click the send icon or tap in the text area to submit your question to the chatbot.</li>
                            </ol>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};