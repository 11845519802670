import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
    isLoggedIn: boolean;
    user_session: string;
    access_token: string;
    id_token: string;
    refresh_token: string;
    email: string;
    id: string;
    sub: string;
}

export interface RootState {
    auth: LoginPayload;
}
const initialState: State = {
    isLoggedIn: false,
    user_session: "",
    access_token: "",
    id_token: "",
    refresh_token: "",
    email: "",
    id: "",
    sub: "",
}

interface LoginPayload {
    isLoggedIn: boolean,
    user_session?: string;
    access_token?: string;
    id_token?: string;
    email?: string;
    refresh_token?:string;
    id?: string;
    sub?: string;
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<LoginPayload>) => {
            state.isLoggedIn = true;
            const {user_session, access_token, id_token, email, id, sub} = action.payload;
            if(user_session != null) {
                state.user_session = user_session
            }
            if(access_token != null) {
                state.access_token = access_token
            }
            if(id_token != null) {
                state.id_token = id_token
            }
            if(email != null) {
                state.email = email
            }
            if(id != null) {
                state.id = id
            }
            if(sub != null) {
                state.sub = sub
            }
        },
        resetAuthData: (state) => {
            state.isLoggedIn = false
            state.user_session = ""
            state.access_token = ""
            state.id_token = ""
            state.refresh_token = ""
            state.email = ""
            state.id = ""
            state.sub = ""
        }
    }
})

export const { login, resetAuthData } = authSlice.actions;
export default authSlice.reducer;