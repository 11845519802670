import React from 'react'
import './layout.css'
const Footer: React.FC = () => {
  return (
    <div className='main-footer'>
      Mount Mary University
    </div>
  )
}

export default Footer
