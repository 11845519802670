import React from 'react'

const Loader: React.FC = () => {
  return (
    <div>
      Loading...
    </div>
  )
}

export default Loader
