import { Tuple, combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {thunk} from "redux-thunk";
import authSlice from "./authSlice";

const persistConfig = {
    key: "root",
    storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
    auth: authSlice,
}));

const store = configureStore({
    reducer: persistedReducer,
    middleware: () => new Tuple(thunk),
    devTools: process.env.NODE_ENV !== "production",
})

const persistor = persistStore(store);

export { store, persistor };