import React from 'react'

const Header: React.FC = () => {
  return (
    <div className='header-media main-header'>
      Mount Mary University
    </div>
  )
}

export default Header
