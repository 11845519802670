import axios from "axios"


const LOCAL_URL = "https://eprgzop69b.execute-api.us-east-1.amazonaws.com/local"
const DEV_URL = "https://e8zstk3s07.execute-api.us-east-1.amazonaws.com/dev"
const PROD_URL = process.env.REACT_APP_API_GW_URL
export const API = () => {
    return axios.create({
        baseURL: PROD_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    })
}