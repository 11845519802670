import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../store/authSlice';

const AuthProviders = ({children}: {children:ReactNode}) => {
    const navigate = useNavigate();
    const {isLoggedIn} = useSelector((state:RootState) => state.auth)
    useEffect(() => {
        
        if(!isLoggedIn) {
            navigate("/")
        }
    })
  return (
    children
  )
}

export default AuthProviders
